import { useCallback, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import config from '../config';
import {
  Error as ApiError,
  List,
  ListMinimal,
  ListOrderField,
  ListStats,
  PaginatedRequest,
  PaginatedResponse,
} from '../types';
import pluckParams from '../utilities/pluck-params';
import prepareHeaders from '../utilities/prepare-headers';
import { useAuth } from './auth';
import { useBulletin } from './bulletin';

const REFRESH_BULLETIN_DEBOUNCE_DELAY = 1000;

const useLists = () => {
  const { token } = useAuth();
  const { refresh: refreshBulletin } = useBulletin();

  const debouncedRefreshBulletin = useDebouncedCallback(() => {
    refreshBulletin(true);
  }, REFRESH_BULLETIN_DEBOUNCE_DELAY);

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleError = (error: Error) => {
    setPending(false);

    if (error.name === 'AbortError') {
      return;
    }

    setError(error);
  };

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  const index = useCallback(
    async (
      options?: PaginatedRequest<ListOrderField> & {
        activated?: boolean;
        name?: string;
        pathName?: string;
        userId?: string;
      },
      signal?: AbortSignal
    ): Promise<PaginatedResponse<List> | void> => {
      const actualOptions = Object.assign(
        {},
        {
          page: 1,
          limit: 10,
          order: 'createdAt',
          direction: 'desc',
        },
        options ?? {}
      );

      setPending(true);

      return fetch(
        `${config.api.url}/lists?${new URLSearchParams({
          page: actualOptions.page.toString(),
          limit: actualOptions.limit.toString(),
          order: actualOptions.order,
          direction: actualOptions.direction,
          ...pluckParams(
            actualOptions,
            'activated',
            'name',
            'pathName',
            'userId'
          ),
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as PaginatedResponse<List>;
        })
        .catch(handleError);
    },
    [token]
  );

  const show = useCallback(
    async (listId: string, signal?: AbortSignal): Promise<List | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists/${listId}`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as List;
        })
        .catch(handleError);
    },
    [token]
  );

  const stats = useCallback(
    async (listId: string, signal?: AbortSignal): Promise<ListStats | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/lists/${listId}/stats?days=${config.dashboardDays}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as ListStats;
        })
        .catch(handleError);
    },
    [token]
  );

  const indexEvents = useCallback(
    async (
      listId: string,
      options?: PaginatedRequest<'createdAt'>,
      signal?: AbortSignal
    ): Promise<PaginatedResponse<Event> | void> => {
      const actualOptions = Object.assign(
        {},
        {
          page: 1,
          limit: 10,
          order: 'createdAt',
          direction: 'desc',
        },
        options ?? {}
      );

      setPending(true);

      return fetch(
        `${config.api.url}/lists/${listId}/events?${new URLSearchParams({
          page: actualOptions.page.toString(),
          limit: actualOptions.limit.toString(),
          order: actualOptions.order,
          direction: actualOptions.direction,
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as PaginatedResponse<Event>;
        })
        .catch(handleError);
    },
    [token]
  );

  const showEvent = useCallback(
    async (
      listId: string,
      eventId: string,
      signal?: AbortSignal
    ): Promise<Event | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists/${listId}/events/${eventId}`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Event;
        })
        .catch(handleError);
    },
    [token]
  );

  const indexAllMinimal = useCallback(
    async (signal?: AbortSignal): Promise<ListMinimal[] | void> => {
      setPending(true);

      return fetch(`${config.api.url}/dashboard/lists`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return ((await response.json()).data ?? []) as ListMinimal[];
        })
        .catch(handleError);
    },
    [token]
  );

  const checkPathName = useCallback(
    async (pathName: string, signal?: AbortSignal): Promise<boolean | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/dashboard/lists?${new URLSearchParams({
          pathName,
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          const responseData = await response.json();
          if (responseData) {
            // Path name is already in use
            if (
              (responseData.data?.length ?? 0) > 0 &&
              responseData.data.includes(pathName)
            ) {
              return false;
            }

            // Path name is reserved
            if (
              responseData.reservedPathNames &&
              responseData.reservedPathNames
                .map((p: string) => p.toLowerCase().trim())
                .includes(pathName.toLowerCase().trim())
            ) {
              return false;
            }
          }

          return true;
        })
        .catch(handleError);
    },
    [token]
  );

  const create = useCallback(
    async (data: Partial<List>): Promise<List | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists`, {
        method: 'POST',
        headers: prepareHeaders(token),
        body: JSON.stringify(data),
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as List;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const update = useCallback(
    async (listId: string, data: Partial<List>): Promise<List | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists/${listId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify(data),
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as List;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const activate = useCallback(
    async (listId: string): Promise<List | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ activated: true }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as List;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const deactivate = useCallback(
    async (listId: string): Promise<List | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ activated: false }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as List;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const pin = useCallback(
    async (listId: string): Promise<List | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ pinned: true }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as List;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const unpin = useCallback(
    async (listId: string): Promise<List | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ pinned: false }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          debouncedRefreshBulletin();

          return (await response.json()) as List;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  const delete_ = useCallback(
    async (listId: string): Promise<boolean | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}`, {
        method: 'DELETE',
        headers: prepareHeaders(token),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return false;
          }

          debouncedRefreshBulletin();

          return true;
        })
        .catch(handleError);
    },
    [token, debouncedRefreshBulletin]
  );

  return {
    pending,
    error,
    resetError,
    index,
    show,
    stats,
    indexEvents,
    showEvent,
    indexAllMinimal,
    checkPathName,
    create,
    update,
    activate,
    deactivate,
    pin,
    unpin,
    delete: delete_,
  };
};

export default useLists;
