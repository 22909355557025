export const information = {
  copyrightYear: '2024',
  companyNumber: '12613006',
  address: ['Fl3x Ltd', '20-22 Wenlock Road', 'London', 'N1 7GU'],
  emailAddress: 'contact@jsonpad.io',
  emailSubject: 'Website enquiry',
  xLink: 'https://x.com/jsonpadio',
  githubLink: 'https://github.com/basementuniverse/jsonpad.io',
  resourcesLinks: [
    {
      text: 'Terms & conditions',
      url: '/terms',
    },
    {
      text: 'Privacy policy',
      url: '/privacy',
    },
    {
      text: 'Report issues',
      url: 'https://github.com/basementuniverse/jsonpad.io/issues',
    },
    // {
    //   text: 'About the company',
    //   url: '/about',
    // },
    {
      text: 'Javascript SDK',
      url: 'https://github.com/basementuniverse/jsonpad-sdk-js',
    },
    {
      text: 'API status',
      url: 'https://stats.uptimerobot.com/6qT7rAbk4j',
    },
  ],
};
