import { faIndent } from '@fortawesome/free-solid-svg-icons';
import CodeEditor from '@uiw/react-textarea-code-editor';
import Ajv, { AnySchemaObject, ErrorObject } from 'ajv';
import addFormats from 'ajv-formats';
import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Alert, Button } from '.';
import theme from '../exports.module.scss';
import classNames from '../utilities/class-names';
import './JsonEditor.scss';

type JsonEditorProps = {
  className?: string;
  name?: string;
  value?: any;
  disabled?: boolean;
  allowEmpty?: boolean;
  schema?: AnySchemaObject;
  onChange?: (value: any, error?: string) => void;
  [key: string]: any;
};

export function JsonEditor({
  className,
  name,
  value,
  disabled,
  allowEmpty,
  onChange,
  schema,
  ...props
}: JsonEditorProps): ReactElement {
  const stringify = (json: any): string => JSON.stringify(json, null, 2);

  const [focused, setFocused] = useState(false);
  const [stringValue, setStringValue] = useState(stringify(value));
  const [jsonValue, setJsonValue] = useState(value);
  const [jsonError, setJsonError] = useState<string | null>(null);
  const [jsonErrorType, setJsonErrorType] = useState<
    'parse' | 'validate' | null
  >(null);

  const ajv = useMemo(() => {
    const ajv = new Ajv();
    addFormats(ajv);

    return ajv;
  }, []);
  const validator = useMemo(() => schema && ajv.compile(schema), [ajv, schema]);

  const handleChange = useCallback(
    async (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      setStringValue(e.target.value);

      let json: any = null;
      let errorMessage: string | null = null;
      let resetJsonValue = false;
      let parseFailed = false;

      // Parse JSON
      try {
        json = JSON.parse(e.target.value);
      } catch (e) {
        const error = e as Error;
        resetJsonValue = true;
        parseFailed = true;

        if (!(allowEmpty && error.message === 'Unexpected end of JSON input')) {
          errorMessage = error.message;
        }
      }

      // Validate JSON
      if (!parseFailed && validator) {
        const result = validator(json);

        if (!result && validator.errors) {
          resetJsonValue = false;
          errorMessage = validator.errors
            .map(
              (e: ErrorObject) =>
                `Schema validation error: ${e.message} ${
                  e.instancePath ? `at ${e.instancePath}` : ''
                }`
            )
            .join('\n');
        }
      }

      setJsonValue(resetJsonValue ? null : json);
      setJsonError(errorMessage || null);
      setJsonErrorType(
        errorMessage ? (parseFailed ? 'parse' : 'validate') : null
      );
    },
    [allowEmpty, validator]
  );

  const handleFormat = useCallback(() => {
    if (!stringValue) {
      return;
    }

    const parsedJsonValue = JSON.parse(stringValue);
    const formattedStringValue = stringify(parsedJsonValue);
    setStringValue(formattedStringValue);
  }, [stringValue]);

  useEffect(() => {
    if (jsonValue || jsonError) {
      onChange?.(jsonValue, jsonError ?? undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(jsonValue), jsonError]);

  useEffect(() => {
    setStringValue(stringify(value));
  }, [value]);

  useEffect(() => {
    if (!schema) {
      return;
    }

    if (!stringValue) {
      return;
    }

    handleChange({ target: { value: stringValue } } as any);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [schema]);

  return (
    <>
      <div
        className={classNames(
          'json-editor',
          className,
          disabled ? 'json-editor-disabled' : '',
          focused ? 'json-editor-focussed' : ''
        )}
        {...props}
      >
        <CodeEditor
          id={props.id ?? name}
          name={name}
          value={stringValue}
          language="json"
          placeholder={'Edit JSON here...'}
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onChange={handleChange}
          padding={12}
          disabled={disabled}
          style={{
            width: '100%',
            backgroundColor: theme.brandBg1,
            lineHeight: '1.5em',
            fontSize: '1em',
            fontFamily: theme.codeFontStack,
          }}
        />
        <Button
          className="json-editor-format-button"
          icon={faIndent}
          disabled={disabled || !!jsonError || !jsonValue}
          onClick={handleFormat}
          minimal
        />
      </div>
      <Alert
        intent={jsonErrorType === 'parse' ? 'danger' : 'warning'}
        show={!!jsonError}
      >
        <p>{jsonError}</p>
      </Alert>
    </>
  );
}
