import { Stats } from './stats';
import { User } from './user-model';

export type List = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  user?: User;
  name: string;
  description: string;
  pathName: string;
  schema: Record<string, any>;
  pinned: boolean;
  readonly: boolean;
  realtime: boolean;
  protected: boolean;
  indexable: boolean;
  activated: boolean;
  locked?: boolean;
  itemCount?: number;
};

export type ListMinimal = {
  id: string;
  name: string;
  pathName: string;
};

export type ListStats = {
  items: Stats<{
    lists: {
      [listId: string]: number;
    };
  }>;
  indexes: Stats<{
    lists: {
      [listId: string]: number;
    };
  }>;
  events: Stats<{
    types: {
      [eventType: string]: number;
    };
  }>;
};

export const listOrderFields = [
  'createdAt',
  'updatedAt',
  'userId',
  'name',
  'pathName',
  'pinned',
  'readonly',
  'realtime',
  'protected',
  'indexable',
  'activated',
] as const;

export type ListOrderField = (typeof listOrderFields)[number];
