import { useCallback, useState } from 'react';
import config from '../config';
import {
  Error as ApiError,
  Item,
  ItemMinimal,
  ItemOrderField,
  ItemStats,
  PaginatedRequest,
  PaginatedResponse,
} from '../types';
import pluckParams from '../utilities/pluck-params';
import prepareHeaders from '../utilities/prepare-headers';
import { useAuth } from './auth';

const useItems = () => {
  const { token } = useAuth();

  const [pending, setPending] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const handleError = (error: Error) => {
    setPending(false);

    if (error.name === 'AbortError') {
      return;
    }

    setError(error);
  };

  const resetError = useCallback(() => {
    setError(null);
  }, []);

  const index = useCallback(
    async (
      listId: string,
      options?: PaginatedRequest<ItemOrderField> & {
        activated?: boolean;
      },
      signal?: AbortSignal
    ): Promise<PaginatedResponse<Item> | void> => {
      const actualOptions = Object.assign(
        {},
        {
          page: 1,
          limit: 10,
          order: 'createdAt',
          direction: 'desc',
        },
        options ?? {}
      );

      setPending(true);

      return fetch(
        `${config.api.url}/lists/${listId}/items?${new URLSearchParams({
          page: actualOptions.page.toString(),
          limit: actualOptions.limit.toString(),
          order: actualOptions.order,
          direction: actualOptions.direction,
          ...pluckParams(actualOptions, 'activated'),
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as PaginatedResponse<Item>;
        })
        .catch(handleError);
    },
    [token]
  );

  const show = useCallback(
    async (
      listId: string,
      itemId: string,
      signal?: AbortSignal
    ): Promise<Item | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/lists/${listId}/items/${itemId}?includeData=true`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Item;
        })
        .catch(handleError);
    },
    [token]
  );

  const stats = useCallback(
    async (
      listId: string,
      itemId: string,
      signal?: AbortSignal
    ): Promise<ItemStats | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists/${listId}/items/${itemId}/stats`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as ItemStats;
        })
        .catch(handleError);
    },
    [token]
  );

  const indexEvents = useCallback(
    async (
      listId: string,
      itemId: string,
      options?: PaginatedRequest<'createdAt'> & {
        restorable?: boolean;
      },
      signal?: AbortSignal
    ): Promise<PaginatedResponse<Event> | void> => {
      const actualOptions = Object.assign(
        {},
        {
          page: 1,
          limit: 10,
          order: 'createdAt',
          direction: 'desc',
          restorable: false,
        },
        options ?? {}
      );

      setPending(true);

      return fetch(
        `${
          config.api.url
        }/lists/${listId}/items/${itemId}/events?${new URLSearchParams({
          page: actualOptions.page.toString(),
          limit: actualOptions.limit.toString(),
          order: actualOptions.order,
          direction: actualOptions.direction,
          restorable: actualOptions.restorable.toString(),
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as PaginatedResponse<Event>;
        })
        .catch(handleError);
    },
    [token]
  );

  const showEvent = useCallback(
    async (
      listId: string,
      itemId: string,
      eventId: string,
      signal?: AbortSignal
    ): Promise<Event | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/lists/${listId}/items/${itemId}/events/${eventId}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Event;
        })
        .catch(handleError);
    },
    [token]
  );

  const restore = useCallback(
    async (
      listId: string,
      itemId: string,
      eventId: string
    ): Promise<Item | void> => {
      setPending(true);
      setError(null);

      return fetch(
        `${config.api.url}/lists/${listId}/items/${itemId}/events/${eventId}/restore`,
        {
          method: 'POST',
          headers: prepareHeaders(token),
        }
      )
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Item;
        })
        .catch(handleError);
    },
    [token]
  );

  const indexAllMinimal = useCallback(
    async (signal?: AbortSignal): Promise<ItemMinimal[] | void> => {
      setPending(true);

      return fetch(`${config.api.url}/dashboard/items`, {
        method: 'GET',
        headers: prepareHeaders(token),
        signal,
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return ((await response.json()).data ?? []) as ItemMinimal[];
        })
        .catch(handleError);
    },
    [token]
  );

  const checkAlias = useCallback(
    async (alias: string, signal?: AbortSignal): Promise<boolean | void> => {
      setPending(true);

      return fetch(
        `${config.api.url}/dashboard/items?${new URLSearchParams({
          alias,
        })}`,
        {
          method: 'GET',
          headers: prepareHeaders(token),
          signal,
        }
      )
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return ((await response.json()).data?.length ?? 0) === 0;
        })
        .catch(handleError);
    },
    [token]
  );

  const create = useCallback(
    async (listId: string, data: Partial<Item>): Promise<Item | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists/${listId}/items`, {
        method: 'POST',
        headers: prepareHeaders(token),
        body: JSON.stringify(data),
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Item;
        })
        .catch(handleError);
    },
    [token]
  );

  const update = useCallback(
    async (
      listId: string,
      itemId: string,
      data: Partial<Item>
    ): Promise<Item | void> => {
      setPending(true);

      return fetch(`${config.api.url}/lists/${listId}/items/${itemId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify(data),
      })
        .then(async response => {
          setPending(false);
          setError(null);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Item;
        })
        .catch(handleError);
    },
    [token]
  );

  const activate = useCallback(
    async (listId: string, itemId: string): Promise<Item | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}/items/${itemId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ activated: true }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Item;
        })
        .catch(handleError);
    },
    [token]
  );

  const deactivate = useCallback(
    async (listId: string, itemId: string): Promise<Item | void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}/items/${itemId}`, {
        method: 'PUT',
        headers: prepareHeaders(token),
        body: JSON.stringify({ activated: false }),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }

          return (await response.json()) as Item;
        })
        .catch(handleError);
    },
    [token]
  );

  const delete_ = useCallback(
    async (listId: string, itemId: string): Promise<void> => {
      setPending(true);
      setError(null);

      return fetch(`${config.api.url}/lists/${listId}/items/${itemId}`, {
        method: 'DELETE',
        headers: prepareHeaders(token),
      })
        .then(async response => {
          setPending(false);

          if (!response.ok) {
            setError(new Error(((await response.json()) as ApiError).message));
            return;
          }
        })
        .catch(handleError);
    },
    [token]
  );

  return {
    pending,
    error,
    resetError,
    index,
    show,
    stats,
    indexEvents,
    showEvent,
    restore,
    indexAllMinimal,
    checkAlias,
    create,
    update,
    activate,
    deactivate,
    delete: delete_,
  };
};

export default useItems;
