import { ReactElement } from 'react';
import {
  Card,
  ResourceIdentifier,
  TokenPermissionActionTag,
  TokenPermissionModeTag,
  TokenPermissionResourceTypeTag,
  Value,
  ValueList,
} from '.';
import { IndexMinimal, ItemMinimal, ListMinimal, Permission } from '../types';
import capitalize from '../utilities/capitalize';
import './TokenPermissionCard.scss';

type TokenPermissionCardProps = {
  index: number;
  permission: Permission;
  fetchListMinimal?: (id: string) => ListMinimal | null;
  fetchItemMinimal?: (id: string) => ItemMinimal | null;
  fetchIndexMinimal?: (id: string) => IndexMinimal | null;
  [key: string]: any;
};

function tokenPermissionName(permission: Permission): string {
  if (permission.action === '*') {
    return `${capitalize(permission.mode)} All`;
  }

  return `${capitalize(permission.mode)} ${capitalize(
    permission.action
  )} ${capitalize(permission.resourceType!)}`;
}

export function TokenPermissionCard({
  index,
  permission,
  fetchListMinimal,
  fetchItemMinimal,
  fetchIndexMinimal,
  ...props
}: TokenPermissionCardProps): ReactElement {
  return (
    <Card className="token-permission-card" {...props}>
      <h3>
        <span className="extra">#{index + 1}</span>{' '}
        {tokenPermissionName(permission)}
      </h3>
      <ValueList>
        <Value
          className="token-permission-card-mode"
          label="Mode"
          value={
            <>
              <TokenPermissionModeTag mode={permission.mode} />
            </>
          }
        />
        <Value
          className="token-permission-card-action"
          label="Action"
          value={
            <>
              <TokenPermissionActionTag action={permission.action} />
            </>
          }
        />
        {permission.resourceType && (
          <Value
            className="token-permission-card-resource-type"
            label="Resource type"
            value={
              <>
                <TokenPermissionResourceTypeTag
                  resourceType={permission.resourceType}
                />
              </>
            }
          />
        )}
        {permission.listIds && (
          <Value
            className="token-permission-card-lists"
            label="Lists"
            value={
              <>
                {permission.listIds.map(listId => (
                  <ResourceIdentifier
                    key={listId}
                    resourceType="list"
                    resource={fetchListMinimal?.(listId) ?? null}
                    wrap
                  />
                ))}
              </>
            }
          />
        )}
        {permission.itemIds && (
          <Value
            className="token-permission-card-items"
            label="Items"
            value={
              <>
                {permission.itemIds.map(itemId => (
                  <ResourceIdentifier
                    key={itemId}
                    resourceType="item"
                    resource={fetchItemMinimal?.(itemId) ?? null}
                    wrap
                  />
                ))}
              </>
            }
          />
        )}
        {permission.indexIds && (
          <Value
            className="token-permission-card-indexes"
            label="Indexes"
            value={
              <>
                {permission.indexIds.map(indexId => (
                  <ResourceIdentifier
                    key={indexId}
                    resourceType="index"
                    resource={fetchIndexMinimal?.(indexId) ?? null}
                    wrap
                  />
                ))}
              </>
            }
          />
        )}
      </ValueList>
    </Card>
  );
}
