import { ReactElement, useId } from 'react';
import {
  Area,
  AreaChart,
  Bar,
  BarChart,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  TooltipProps,
  XAxis,
  YAxis,
} from 'recharts';
import config from '../config';
import formatDate from '../utilities/format-date';
import './Chart.scss';

type ChartProps = {
  type: 'line' | 'bar' | 'area';
  dateResolution?: 'days' | 'minutes';
  metrics: {
    date: string;
    [key: string]: any;
  }[];
  colours?: {
    [key: string]: string;
  };
};

const formatChartDate = (
  date: string,
  dateResolution: 'days' | 'minutes' = 'days'
): string =>
  formatDate(
    date,
    {
      days: config.chartDateFormatDays,
      minutes: config.chartDateFormatMinutes,
    }[dateResolution]
  );

export function Chart({
  type,
  dateResolution = 'days',
  metrics,
  colours = {
    '*': '#fffcf2',
  },
}: ChartProps): ReactElement {
  const chartId = useId();

  const series = [
    ...new Set(metrics.flatMap(metric => Object.keys(metric))),
  ].filter(key => key !== 'date');

  const ChartTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<number, string>): ReactElement =>
    active ? (
      <div className="chart-tooltip">
        <table>
          <tbody>
            <tr>
              <td>date</td>
              <td>{formatChartDate(label, dateResolution)}</td>
            </tr>
            {series
              .map(s => [s, payload?.[0]?.payload?.[s]])
              .filter(
                ([s, c]) =>
                  c !== undefined && ['string', 'number'].includes(typeof c)
              )
              .map(([s, c]) => (
                <tr
                  key={s.toString()}
                  style={{
                    color: colours[s] ?? colours['*'] ?? '#fffcf2',
                  }}
                >
                  <td>{s.toString()}</td>
                  <td>{c.toString()}</td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    ) : (
      <></>
    );

  let ChartContainer;
  switch (type) {
    case 'line':
      ChartContainer = LineChart;
      break;

    case 'bar':
      ChartContainer = BarChart;
      break;

    case 'area':
      ChartContainer = AreaChart;
      break;
  }

  return (
    <ResponsiveContainer className="chart" width={'100%'} height={300}>
      <ChartContainer
        data={metrics
          .map(metric => ({
            ...metric,
            date: new Date(metric.date),
          }))
          .sort((a, b) => a.date.getTime() - b.date.getTime())}
        margin={{ top: 20, right: 20, left: -30 }}
      >
        {type === 'area' && (
          <defs>
            {series.map(s => (
              <linearGradient
                id={`fillGradient_${chartId}_${s}`}
                x1="0"
                y1="0"
                x2="0"
                y2={Math.max(1, ...metrics.map(m => m[s]))}
              >
                <stop
                  offset="5%"
                  stopColor={colours[s] ?? colours['*'] ?? '#fffcf2'}
                  stopOpacity={0.5}
                />
                <stop
                  offset="95%"
                  stopColor={colours[s] ?? colours['*'] ?? '#fffcf2'}
                  stopOpacity={0}
                />
              </linearGradient>
            ))}
          </defs>
        )}
        {(type => {
          switch (type) {
            case 'line':
              return series.map(s => (
                <Line
                  key={s}
                  type="monotone"
                  dataKey={s}
                  stroke={colours[s] ?? colours['*'] ?? '#fffcf2'}
                  strokeWidth={2}
                />
              ));

            case 'bar':
              return series.map(s => (
                <Bar
                  key={s}
                  dataKey={s}
                  stackId="a"
                  fill={colours[s] ?? colours['*'] ?? '#fffcf2'}
                />
              ));

            case 'area':
              return series.map(s => (
                <Area
                  type="bump"
                  key={s}
                  dataKey={s}
                  stackId="a"
                  fill={`url(#fillGradient_${chartId}_${s})`}
                  stroke={colours[s] ?? colours['*'] ?? '#fffcf2'}
                  strokeWidth={2}
                />
              ));
          }
        })(type)}
        <XAxis
          dataKey="date"
          tick={{ fontSize: 12 }}
          tickFormatter={date => formatChartDate(date, dateResolution)}
        />
        <YAxis tick={{ fontSize: 12 }} />
        <Tooltip content={<ChartTooltip />} cursor={type === 'line'} />
      </ChartContainer>
    </ResponsiveContainer>
  );
}
