import { User } from './user-model';

export enum EventStream {
  System = 'system',
  Unknown = 'unknown',
  Test = 'test',
  User = 'user',
  Token = 'token',
  List = 'list',
  Index = 'index',
  Item = 'item',
  Setting = 'setting',
}

export const EventStreamQueryParam = {
  encode: (value?: EventStream | null | undefined): string | undefined =>
    value ? (value as string) : undefined,

  decode: (
    value?: string | (string | null | undefined)[] | null | undefined
  ): EventStream | undefined =>
    value && Object.values(EventStream).includes(value as any)
      ? (value as EventStream)
      : undefined,
};

export enum EventType {
  System = 'system',
  Unknown = 'unknown',
  Test = 'test',
  UserCreated = 'user-created',
  UserUpdated = 'user-updated',
  UserDeleted = 'user-deleted',
  UserRegistered = 'user-registered',
  UserForgotPassword = 'user-forgot-password',
  TokenCreated = 'token-created',
  TokenUpdated = 'token-updated',
  TokenDeleted = 'token-deleted',
  ListCreated = 'list-created',
  ListUpdated = 'list-updated',
  ListDeleted = 'list-deleted',
  IndexCreated = 'index-created',
  IndexUpdated = 'index-updated',
  IndexDeleted = 'index-deleted',
  ItemCreated = 'item-created',
  ItemUpdated = 'item-updated',
  ItemDeleted = 'item-deleted',
  ItemRestored = 'item-restored',
  SettingUpdated = 'setting-updated',
  SubscriptionCreated = 'subscription-created',
  SubscriptionUpdated = 'subscription-updated',
  SubscriptionDeleted = 'subscription-deleted',
  SubscriptionPaymentSucceeded = 'subscription-payment-succeeded',
  SubscriptionPaymentFailed = 'subscription-payment-failed',
}

export const EventTypeQueryParam = {
  encode: (value?: EventType | null | undefined): string | undefined =>
    value ? (value as string) : undefined,

  decode: (
    value?: string | (string | null | undefined)[] | null | undefined
  ): EventType | undefined =>
    value && Object.values(EventType).includes(value as any)
      ? (value as EventType)
      : undefined,
};

export type Event = {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  user?: User;
  modelId: string;
  stream: EventStream;
  type: EventType;
  version?: string;
  currentVersion?: boolean;
  snapshot: Record<string, any> | null;
  attachments: Record<string, any> | null;
};

export const eventOrderFields = [
  'createdAt',
  'userId',
  'modelId',
  'stream',
  'type',
] as const;

export type EventOrderField = (typeof eventOrderFields)[number];
