import { faCircleCheck } from '@fortawesome/free-regular-svg-icons';
import {
  IconDefinition,
  faAsterisk,
  faBan,
  faChartLine,
  faChartSimple,
  faEdit,
  faEye,
  faFileCode,
  faFolder,
  faSquarePlus,
  faTags,
  faTimes,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { SingleValue } from 'react-select';
import { Card, ResourcePicker, Select, Value, ValueList } from '.';
import {
  Permission,
  PermissionAction,
  PermissionMode,
  PermissionResourceType,
} from '../types';
import { SelectOptionProps } from './Select';

type TokenPermissionEditorProps = {
  index: number;
  permission: Permission;
  pending?: boolean;
  onChange?: (permission: Permission) => void;
};

export function TokenPermissionEditor({
  index,
  permission,
  pending,
  onChange,
}: TokenPermissionEditorProps): ReactElement {
  const [value, setValue] = useState<Permission>({
    mode: 'allow',
    action: '*',
  });

  useEffect(() => {
    setValue(permission);
  }, [permission]);

  const [resourceTypeEnabled, setResourceTypeEnabled] = useState<boolean>(true);
  const [listIdsEnabled, setListIdsEnabled] = useState<boolean>(true);
  const [itemIdsEnabled, setItemIdsEnabled] = useState<boolean>(true);
  const [indexIdsEnabled, setIndexIdsEnabled] = useState<boolean>(true);

  useEffect(() => {
    let resourceTypeEnabled = true;
    let listIdsEnabled = false;
    let itemIdsEnabled = false;
    let indexIdsEnabled = false;

    // All/None permissions
    if (value.action === '*') {
      resourceTypeEnabled = false;
    }

    // Create list permission
    // (do nothing, this matches the default values)

    // Other list permissions
    if (
      ['view', 'update', 'delete'].includes(value.action) &&
      value.resourceType === 'list'
    ) {
      listIdsEnabled = true;
    }

    // Create item permission
    if (value.action === 'create' && value.resourceType === 'item') {
      listIdsEnabled = true;
    }

    // Other item permissions
    if (
      ['view', 'update', 'delete'].includes(value.action) &&
      value.resourceType === 'item'
    ) {
      listIdsEnabled = true;
      itemIdsEnabled = true;
    }

    // Create index permission
    if (value.action === 'create' && value.resourceType === 'index') {
      listIdsEnabled = true;
    }

    // Other index permissions
    if (
      ['view', 'update', 'delete'].includes(value.action) &&
      value.resourceType === 'index'
    ) {
      listIdsEnabled = true;
      indexIdsEnabled = true;
    }

    // View list/item/index events permission
    if (value.action === 'view' && value.resourceType === 'event') {
      listIdsEnabled = true;
      itemIdsEnabled = true;
      indexIdsEnabled = true;
    }

    // View list/item/index stats permission
    if (value.action === 'view' && value.resourceType === 'stats') {
      listIdsEnabled = true;
      itemIdsEnabled = true;
      indexIdsEnabled = true;
    }

    setResourceTypeEnabled(resourceTypeEnabled);
    setListIdsEnabled(listIdsEnabled);
    setItemIdsEnabled(itemIdsEnabled);
    setIndexIdsEnabled(indexIdsEnabled);
  }, [value]);

  const handleChangeMode = useCallback(
    (
      selected: SingleValue<
        SelectOptionProps<{
          mode: PermissionMode;
        }>
      >
    ) => {
      if (!selected) {
        return;
      }

      const newValue = {
        ...value,
        mode: selected.value.mode,
      };

      setValue(newValue);
      onChange?.(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const handleChangeAction = useCallback(
    (
      selected: SingleValue<
        SelectOptionProps<{
          action: PermissionAction;
        }>
      >
    ) => {
      if (!selected) {
        return;
      }

      const newValue = {
        ...value,
        action: selected.value.action,
      };

      setValue(newValue);
      onChange?.(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const handleChangeResourceType = useCallback(
    (
      selected: SingleValue<
        SelectOptionProps<{
          resourceType: PermissionResourceType | null;
        }>
      >
    ) => {
      if (!selected) {
        return;
      }

      const newValue = {
        ...value,
        resourceType: selected.value.resourceType ?? undefined,
      };

      setValue(newValue);
      onChange?.(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const handleSelectLists = useCallback(
    (selected: string[]) => {
      const newValue = {
        ...value,
        listIds:
          selected && Array.isArray(selected) && selected.length > 0
            ? selected
            : undefined,
      };

      setValue(newValue);
      onChange?.(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const handleSelectItems = useCallback(
    (selected: string[]) => {
      const newValue = {
        ...value,
        itemIds:
          selected && Array.isArray(selected) && selected.length > 0
            ? selected
            : undefined,
      };

      setValue(newValue);
      onChange?.(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const handleSelectIndexes = useCallback(
    (selected: string[]) => {
      const newValue = {
        ...value,
        indexIds:
          selected && Array.isArray(selected) && selected.length > 0
            ? selected
            : undefined,
      };

      setValue(newValue);
      onChange?.(newValue);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [value]
  );

  const permissionModeOptions: SelectOptionProps<{
    mode: PermissionMode;
  }>[] = [
    {
      label: 'Allow',
      icon: faCircleCheck as IconDefinition,
      value: {
        mode: 'allow',
      },
    },
    {
      label: 'Block',
      icon: faBan,
      value: {
        mode: 'block',
      },
    },
  ];

  const permissionActionOptions: SelectOptionProps<{
    action: PermissionAction;
  }>[] = [
    {
      label: 'All',
      icon: faAsterisk,
      value: {
        action: '*',
      },
    },
    {
      label: 'Create',
      icon: faSquarePlus,
      value: {
        action: 'create',
      },
    },
    {
      label: 'View',
      icon: faEye,
      value: {
        action: 'view',
      },
    },
    {
      label: 'Update',
      icon: faEdit,
      value: {
        action: 'update',
      },
    },
    {
      label: 'Delete',
      icon: faTrash,
      value: {
        action: 'delete',
      },
    },
  ];

  const getPermissionResourceTypeOptions = useCallback((): SelectOptionProps<{
    resourceType: PermissionResourceType | null;
  }>[] => {
    let listOptionEnabled = false;
    let itemOptionEnabled = false;
    let indexOptionEnabled = false;
    let eventOptionEnabled = false;
    let statsOptionEnabled = false;

    if (value.action === 'create') {
      listOptionEnabled = true;
      itemOptionEnabled = true;
      indexOptionEnabled = true;
    }

    if (value.action === 'view') {
      listOptionEnabled = true;
      itemOptionEnabled = true;
      indexOptionEnabled = true;
      eventOptionEnabled = true;
      statsOptionEnabled = true;
    }

    if (['update', 'delete'].includes(value.action)) {
      listOptionEnabled = true;
      itemOptionEnabled = true;
      indexOptionEnabled = true;
    }

    return [
      {
        label: 'Clear',
        icon: faTimes,
        value: {
          resourceType: null,
        },
      },
      {
        label: 'List',
        icon: faFolder,
        value: {
          resourceType: 'list',
        },
        disabled: !listOptionEnabled,
      },
      {
        label: 'Item',
        icon: faFileCode,
        value: {
          resourceType: 'item',
        },
        disabled: !itemOptionEnabled,
      },
      {
        label: 'Index',
        icon: faTags,
        value: {
          resourceType: 'index',
        },
        disabled: !indexOptionEnabled,
      },
      {
        label: 'Events',
        icon: faChartSimple,
        value: {
          resourceType: 'event',
        },
        disabled: !eventOptionEnabled,
      },
      {
        label: 'Stats',
        icon: faChartLine,
        value: {
          resourceType: 'stats',
        },
        disabled: !statsOptionEnabled,
      },
    ];
  }, [value.action]);

  return (
    <Card className="token-permission-editor" background={false}>
      <h2>
        <span className="extra">#{index + 1}</span>
      </h2>
      <ValueList fullWidth>
        <Value
          label="Mode"
          htmlFor={`permissions-${index}-mode`}
          value={
            <Select
              id={`permissions-${index}-mode`}
              name={`permissions-${index}-mode`}
              options={permissionModeOptions}
              value={permissionModeOptions.find(
                option => option.value.mode === value.mode
              )}
              disabled={pending}
              onChange={handleChangeMode as any}
            />
          }
        />
        <Value
          label="Action"
          htmlFor={`permissions-${index}-action`}
          value={
            <Select
              id={`permissions-${index}-action`}
              name={`permissions-${index}-action`}
              options={permissionActionOptions}
              value={permissionActionOptions.find(
                option => option.value.action === value.action
              )}
              disabled={pending}
              onChange={handleChangeAction as any}
            />
          }
        />
        {(options => (
          <Value
            label="Resource type"
            htmlFor={`permissions-${index}-resourceType`}
            value={
              <Select
                id={`permissions-${index}-resourceType`}
                name={`permissions-${index}-resourceType`}
                options={options}
                value={
                  options.find(
                    option => option.value.resourceType === value.resourceType
                  ) ?? null
                }
                disabled={pending || !resourceTypeEnabled}
                onChange={handleChangeResourceType as any}
              />
            }
          />
        ))(getPermissionResourceTypeOptions())}
        <Value
          label="Lists"
          htmlFor={`permissions-${index}-listIds`}
          value={
            <ResourcePicker
              resourceType="list"
              name={`permissions-${index}-listIds`}
              value={permission.listIds}
              onChange={handleSelectLists}
              disabled={pending || !listIdsEnabled}
            />
          }
        />
        <Value
          label="Items"
          htmlFor={`permissions-${index}-itemIds`}
          value={
            <ResourcePicker
              resourceType="item"
              name={`permissions-${index}-itemIds`}
              value={permission.itemIds}
              onChange={handleSelectItems}
              disabled={pending || !itemIdsEnabled}
            />
          }
        />
        <Value
          label="Indexes"
          htmlFor={`permissions-${index}-indexIds`}
          value={
            <ResourcePicker
              resourceType="index"
              name={`permissions-${index}-indexIds`}
              value={permission.indexIds}
              onChange={handleSelectIndexes}
              disabled={pending || !indexIdsEnabled}
            />
          }
        />
      </ValueList>
    </Card>
  );
}
