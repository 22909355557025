import dayjs from 'dayjs';
import { useCallback } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import config from '../config';
import { IndexMinimal, ItemMinimal, ListMinimal } from '../types';
import useIndexes from './indexes';
import useItems from './items';
import useLists from './lists';
import { useLocalStorage } from './local-storage';

const REFRESH_CACHED_RESOURCES_DEBOUNCE_DELAY = 1000;

export const useCachedResources = () => {
  const { indexAllMinimal: indexAllListsMinimal } = useLists();
  const { indexAllMinimal: indexAllItemsMinimal } = useItems();
  const { indexAllMinimal: indexAllIndexesMinimal } = useIndexes();

  const [cachedLists, setCachedLists] = useLocalStorage('cached-lists', '[]');
  const [cachedItems, setCachedItems] = useLocalStorage('cached-items', '[]');
  const [cachedIndexes, setCachedIndexes] = useLocalStorage(
    'cached-indexes',
    '[]'
  );

  const [listsLastRefreshAt, setListsLastRefreshAt] = useLocalStorage(
    'lists-last-refresh-at',
    new Date().toISOString()
  );
  const [itemsLastRefreshAt, setItemsLastRefreshAt] = useLocalStorage(
    'items-last-refresh-at',
    new Date().toISOString()
  );
  const [indexesLastRefreshAt, setIndexesLastRefreshAt] = useLocalStorage(
    'indexes-last-refresh-at',
    new Date().toISOString()
  );

  const refreshLists = useCallback(async () => {
    setCachedLists(await indexAllListsMinimal());
    setListsLastRefreshAt(new Date().toISOString());
  }, [setCachedLists, indexAllListsMinimal, setListsLastRefreshAt]);

  const debouncedRefreshLists = useDebouncedCallback(
    refreshLists,
    REFRESH_CACHED_RESOURCES_DEBOUNCE_DELAY
  );

  const refreshItems = useCallback(async () => {
    setCachedItems(await indexAllItemsMinimal());
    setItemsLastRefreshAt(new Date().toISOString());
  }, [setCachedItems, indexAllItemsMinimal, setItemsLastRefreshAt]);

  const debouncedRefreshItems = useDebouncedCallback(
    refreshItems,
    REFRESH_CACHED_RESOURCES_DEBOUNCE_DELAY
  );

  const refreshIndexes = useCallback(async () => {
    setCachedIndexes(await indexAllIndexesMinimal());
    setIndexesLastRefreshAt(new Date().toISOString());
  }, [setCachedIndexes, indexAllIndexesMinimal, setIndexesLastRefreshAt]);

  const debouncedRefreshIndexes = useDebouncedCallback(
    refreshIndexes,
    REFRESH_CACHED_RESOURCES_DEBOUNCE_DELAY
  );

  const list = useCallback(
    (id: string): ListMinimal | null => {
      const refreshInterval = Math.max(
        1,
        config.cachedResourcesRefreshInterval
      );

      if (
        !cachedLists ||
        !listsLastRefreshAt ||
        dayjs(listsLastRefreshAt).isBefore(
          dayjs().subtract(refreshInterval, 'seconds')
        )
      ) {
        debouncedRefreshLists();
      }

      if (cachedLists && Array.isArray(cachedLists) && cachedLists.length > 0) {
        return cachedLists.find(list => list.id === id) || null;
      }

      return null;
    },
    [cachedLists, listsLastRefreshAt, debouncedRefreshLists]
  );

  const item = useCallback(
    (id: string): ItemMinimal | null => {
      const refreshInterval = Math.max(
        1,
        config.cachedResourcesRefreshInterval
      );

      if (
        !cachedItems ||
        !itemsLastRefreshAt ||
        dayjs(itemsLastRefreshAt).isBefore(
          dayjs().subtract(refreshInterval, 'seconds')
        )
      ) {
        debouncedRefreshItems();
      }

      if (cachedItems && Array.isArray(cachedItems) && cachedItems.length > 0) {
        return cachedItems.find(item => item.id === id) || null;
      }

      return null;
    },
    [cachedItems, itemsLastRefreshAt, debouncedRefreshItems]
  );

  const index = useCallback(
    (id: string): IndexMinimal | null => {
      const refreshInterval = Math.max(
        1,
        config.cachedResourcesRefreshInterval
      );

      if (
        !cachedIndexes ||
        !indexesLastRefreshAt ||
        dayjs(indexesLastRefreshAt).isBefore(
          dayjs().subtract(refreshInterval, 'seconds')
        )
      ) {
        debouncedRefreshIndexes();
      }

      if (
        cachedIndexes &&
        Array.isArray(cachedIndexes) &&
        cachedIndexes.length > 0
      ) {
        return cachedIndexes.find(index => index.id === id) || null;
      }

      return null;
    },
    [cachedIndexes, indexesLastRefreshAt, debouncedRefreshIndexes]
  );

  return {
    refreshLists,
    lists: (cachedLists ?? []) as ListMinimal[],
    list,
    refreshItems,
    items: (cachedItems ?? []) as ItemMinimal[],
    item,
    refreshIndexes,
    indexes: (cachedIndexes ?? []) as IndexMinimal[],
    index,
  };
};

export default useCachedResources;
