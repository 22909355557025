import {
  IconDefinition,
  faBug,
  faCircleExclamation,
  faClock,
  faFileCode,
  faFolder,
  faKey,
  faMedal,
  faQuestionCircle,
  faReceipt,
  faServer,
  faTags,
  faUserCheck,
  faUserMinus,
  faUserPen,
  faUserPlus,
  faUserSlash,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { EventType } from '../types';
import classNames from '../utilities/class-names';
import eventTypeColours from '../utilities/event-type-colours';

type EventTypeTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
  colour: string;
};

const eventTypeTagStyles: {
  [key in EventType]: EventTypeTagStyle;
} = {
  system: {
    icon: faServer,
    className: 'event-type-tag-system',
    label: 'System',
    colour: eventTypeColours['system'],
  },
  unknown: {
    icon: faQuestionCircle,
    className: 'event-type-tag-unknown',
    label: 'Unknown',
    colour: eventTypeColours['unknown'],
  },
  test: {
    icon: faBug,
    className: 'event-type-tag-test',
    label: 'Test',
    colour: eventTypeColours['test'],
  },
  'user-created': {
    icon: faUserPlus,
    className: 'event-type-tag-user-created',
    label: 'User created',
    colour: eventTypeColours['user-created'],
  },
  'user-updated': {
    icon: faUserPen,
    className: 'event-type-tag-user-updated',
    label: 'User updated',
    colour: eventTypeColours['user-updated'],
  },
  'user-deleted': {
    icon: faUserMinus,
    className: 'event-type-tag-user-deleted',
    label: 'User deleted',
    colour: eventTypeColours['user-deleted'],
  },
  'user-registered': {
    icon: faUserCheck,
    className: 'event-type-tag-user-registered',
    label: 'User registered',
    colour: eventTypeColours['user-registered'],
  },
  'user-forgot-password': {
    icon: faUserSlash,
    className: 'event-type-tag-user-forgot-password',
    label: 'User forgot password',
    colour: eventTypeColours['user-forgot-password'],
  },
  'token-created': {
    icon: faKey,
    className: 'event-type-tag-token-created',
    label: 'Token created',
    colour: eventTypeColours['token-created'],
  },
  'token-updated': {
    icon: faKey,
    className: 'event-type-tag-token-updated',
    label: 'Token updated',
    colour: eventTypeColours['token-updated'],
  },
  'token-deleted': {
    icon: faKey,
    className: 'event-type-tag-token-deleted',
    label: 'Token deleted',
    colour: eventTypeColours['token-deleted'],
  },
  'list-created': {
    icon: faFolder,
    className: 'event-type-tag-list-created',
    label: 'List created',
    colour: eventTypeColours['list-created'],
  },
  'list-updated': {
    icon: faFolder,
    className: 'event-type-tag-list-updated',
    label: 'List updated',
    colour: eventTypeColours['list-updated'],
  },
  'list-deleted': {
    icon: faFolder,
    className: 'event-type-tag-list-deleted',
    label: 'List deleted',
    colour: eventTypeColours['list-deleted'],
  },
  'item-created': {
    icon: faFileCode,
    className: 'event-type-tag-item-created',
    label: 'Item created',
    colour: eventTypeColours['item-created'],
  },
  'item-updated': {
    icon: faFileCode,
    className: 'event-type-tag-item-updated',
    label: 'Item updated',
    colour: eventTypeColours['item-updated'],
  },
  'item-deleted': {
    icon: faFileCode,
    className: 'event-type-tag-item-deleted',
    label: 'Item deleted',
    colour: eventTypeColours['item-deleted'],
  },
  'item-restored': {
    icon: faFileCode,
    className: 'event-type-tag-item-restored',
    label: 'Item restored',
    colour: eventTypeColours['item-restored'],
  },
  'index-created': {
    icon: faTags,
    className: 'event-type-tag-index-created',
    label: 'Index created',
    colour: eventTypeColours['index-created'],
  },
  'index-updated': {
    icon: faTags,
    className: 'event-type-tag-index-updated',
    label: 'Index updated',
    colour: eventTypeColours['index-updated'],
  },
  'index-deleted': {
    icon: faTags,
    className: 'event-type-tag-index-deleted',
    label: 'Index deleted',
    colour: eventTypeColours['index-deleted'],
  },
  'subscription-created': {
    icon: faMedal,
    className: 'event-type-tag-subscription-created',
    label: 'Subscription created',
    colour: eventTypeColours['subscription-created'],
  },
  'subscription-updated': {
    icon: faMedal,
    className: 'event-type-tag-subscription-updated',
    label: 'Subscription updated',
    colour: eventTypeColours['subscription-updated'],
  },
  'subscription-deleted': {
    icon: faMedal,
    className: 'event-type-tag-subscription-deleted',
    label: 'Subscription deleted',
    colour: eventTypeColours['subscription-deleted'],
  },
  'subscription-payment-succeeded': {
    icon: faReceipt,
    className: 'event-type-tag-subscription-payment-succeeded',
    label: 'Subscription payment succeeded',
    colour: eventTypeColours['subscription-payment-succeeded'],
  },
  'subscription-payment-failed': {
    icon: faCircleExclamation,
    className: 'event-type-tag-subscription-payment-failed',
    label: 'Subscription payment failed',
    colour: eventTypeColours['subscription-payment-failed'],
  },
  'setting-updated': {
    icon: faClock,
    className: 'event-type-tag-setting-updated',
    label: 'Setting updated',
    colour: eventTypeColours['setting-updated'],
  },
};

type EventTypeTagProps = {
  eventType: EventType;
};

export function EventTypeTag({ eventType }: EventTypeTagProps): ReactElement {
  let { icon, className, label, colour } = eventTypeTagStyles[eventType];

  return (
    <Tag
      show
      className={classNames('event-type-tag', className)}
      icon={icon}
      colour={colour}
    >
      {label}
    </Tag>
  );
}
