import { EventType } from '../types/event-model';

const eventTypeColours: Record<EventType, string> = {
  [EventType.System]: '#fffcf2',
  [EventType.Unknown]: '#b1aba1',
  [EventType.Test]: '#b1aba1',
  [EventType.UserCreated]: '#5cb85c',
  [EventType.UserUpdated]: '#45a0bc',
  [EventType.UserDeleted]: '#d9534f',
  [EventType.UserRegistered]: '#5cb85c',
  [EventType.UserForgotPassword]: '#f0ad4e',
  [EventType.TokenCreated]: '#5cb85c',
  [EventType.TokenUpdated]: '#45a0bc',
  [EventType.TokenDeleted]: '#d9534f',
  [EventType.ListCreated]: '#5cb85c',
  [EventType.ListUpdated]: '#45a0bc',
  [EventType.ListDeleted]: '#d9534f',
  [EventType.IndexCreated]: '#5cb85c',
  [EventType.IndexUpdated]: '#45a0bc',
  [EventType.IndexDeleted]: '#d9534f',
  [EventType.ItemCreated]: '#5cb85c',
  [EventType.ItemUpdated]: '#45a0bc',
  [EventType.ItemDeleted]: '#d9534f',
  [EventType.ItemRestored]: '#f0ad4e',
  [EventType.SettingUpdated]: '#f0ad4e',
  [EventType.SubscriptionCreated]: '#5cb85c',
  [EventType.SubscriptionUpdated]: '#45a0bc',
  [EventType.SubscriptionDeleted]: '#d9534f',
  [EventType.SubscriptionPaymentSucceeded]: '#f0ad4e',
  [EventType.SubscriptionPaymentFailed]: '#d9534f',
};

export default eventTypeColours;
