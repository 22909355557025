import {
  faFileCode,
  faFolder,
  faKey,
  faTags,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Rating } from '.';
import theme from '../exports.module.scss';
import { SearchResult } from '../types/search-result';
import './SearchResultsMenuItem.scss';

type SearchResultsMenuItemProps = {
  item: SearchResult;
};

export function SearchResultsMenuItem({
  item,
}: SearchResultsMenuItemProps): ReactElement {
  const relevance = useMemo(
    () => (
      <Rating
        rating={item.relevance}
        maxRating={1}
        colours={{
          '0': theme.intentDanger,
          '0.4': theme.intentWarning,
          '0.6': theme.intentInfo,
          '0.8': theme.intentSuccess,
        }}
      />
    ),
    [item.relevance]
  );

  const tokenItem = useMemo(() => {
    const entity = item.data as { id: string; name: string };
    return (
      <Link to={`/tokens/${entity.id}`}>
        <div className="search-results-menu-item search-results-menu-item-token">
          <div className="search-results-menu-item-icon">
            <FontAwesomeIcon icon={faKey} />
          </div>
          <div className="search-results-menu-item-details">
            <span className="search-results-menu-item-id">{entity.id}</span>
            <span className="search-results-menu-item-name">
              {entity.name || 'Unnamed token'}
            </span>
          </div>
          <div className="search-results-menu-item-relevance">{relevance}</div>
        </div>
      </Link>
    );
  }, [item, relevance]);

  const listItem = useMemo(() => {
    const entity = item.data as { id: string; name: string };
    return (
      <Link to={`/lists/${entity.id}`}>
        <div className="search-results-menu-item search-results-menu-item-list">
          <div className="search-results-menu-item-icon">
            <FontAwesomeIcon icon={faFolder} />
          </div>
          <div className="search-results-menu-item-details">
            <span className="search-results-menu-item-id">{entity.id}</span>
            <span className="search-results-menu-item-name">
              {entity.name || 'Unnamed list'}
            </span>
          </div>
          <div className="search-results-menu-item-relevance">{relevance}</div>
        </div>
      </Link>
    );
  }, [item, relevance]);

  const itemItem = useMemo(() => {
    const entity = item.data as { id: string; listId: string };
    return (
      <Link to={`/lists/${entity.listId}/items/${entity.id}`}>
        <div className="search-results-menu-item search-results-menu-item-item">
          <div className="search-results-menu-item-icon">
            <FontAwesomeIcon icon={faFileCode} />
          </div>
          <div className="search-results-menu-item-details">
            <span className="search-results-menu-item-id">{entity.id}</span>
          </div>
          <div className="search-results-menu-item-relevance">{relevance}</div>
        </div>
      </Link>
    );
  }, [item, relevance]);

  const indexItem = useMemo(() => {
    const entity = item.data as { id: string; listId: string; name: string };
    return (
      <Link to={`/lists/${entity.listId}/indexes/${entity.id}`}>
        <div className="search-results-menu-item search-results-menu-item-index">
          <div className="search-results-menu-item-icon">
            <FontAwesomeIcon icon={faTags} />
          </div>
          <div className="search-results-menu-item-details">
            <span className="search-results-menu-item-id">{entity.id}</span>
            <span className="search-results-menu-item-name">
              {entity.name || 'Unnamed index'}
            </span>
          </div>
          <div className="search-results-menu-item-relevance">{relevance}</div>
        </div>
      </Link>
    );
  }, [item, relevance]);

  const userItem = useMemo(() => {
    const entity = item.data as { id: string; displayName: string };
    return (
      <Link to={`/users/${entity.id}`}>
        <div className="search-results-menu-item search-results-menu-item-user">
          <div className="search-results-menu-item-icon">
            <FontAwesomeIcon icon={faUserAlt} />
          </div>
          <div className="search-results-menu-item-details">
            <span className="search-results-menu-item-id">{entity.id}</span>
            <span className="search-results-menu-item-name">
              {entity.displayName || 'Unnamed user'}
            </span>
          </div>
          <div className="search-results-menu-item-relevance">{relevance}</div>
        </div>
      </Link>
    );
  }, [item, relevance]);

  return (
    <>
      {item.type === 'token' && tokenItem}
      {item.type === 'list' && listItem}
      {item.type === 'item' && itemItem}
      {item.type === 'index' && indexItem}
      {item.type === 'user' && userItem}
    </>
  );
}
