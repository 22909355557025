import {
  IconDefinition,
  faClipboard,
  faClipboardCheck,
  faFileCode,
  faFolder,
  faKey,
  faTags,
  faTimes,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactElement, useCallback, useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import {
  IndexMinimal,
  ItemMinimal,
  ListMinimal,
  TokenMinimal,
  UserMinimal,
} from '../types';
import classNames from '../utilities/class-names';
import './ResourceIdentifier.scss';

const COPY_TO_CLIPBOARD_RESET_DELAY = 3000;

type ResourceIdentifierProps = {
  resourceType: 'list' | 'item' | 'index' | 'token' | 'user';
  resource:
    | ListMinimal
    | ItemMinimal
    | IndexMinimal
    | TokenMinimal
    | UserMinimal
    | null;
  wrap?: boolean;
  closable?: boolean;
  copyable?: boolean;
  onClose?: () => void;
};

export function ResourceIdentifier({
  resourceType,
  resource,
  wrap,
  closable,
  copyable,
  onClose,
}: ResourceIdentifierProps): ReactElement {
  const [icon, setIcon] = useState<IconDefinition | undefined>(undefined);
  const [label, setLabel] = useState<string>('');
  const [extra, setExtra] = useState<string | undefined>(undefined);
  const [link, setLink] = useState<string | undefined>(undefined);

  const [idCopiedToClipboard, setIdCopiedToClipboard] = useState(false);

  useEffect(() => {
    if (idCopiedToClipboard) {
      setTimeout(() => {
        setIdCopiedToClipboard(false);
      }, COPY_TO_CLIPBOARD_RESET_DELAY);
    }
  }, [idCopiedToClipboard]);

  useEffect(() => {
    switch (resourceType) {
      case 'list':
        setIcon(faFolder);
        if (resource) {
          const listMinimal = resource as ListMinimal;
          setLabel(listMinimal.name || 'Unnamed list');
          setExtra(listMinimal.id);
          setLink(`/lists/${listMinimal.id}`);
        }
        break;

      case 'item':
        setIcon(faFileCode);
        if (resource) {
          const itemMinimal = resource as ItemMinimal;
          setLabel(itemMinimal.alias || 'Unnamed item');
          setExtra(itemMinimal.id);
          setLink(`/lists/${itemMinimal.listId}/items/${itemMinimal.id}`);
        }
        break;

      case 'index':
        setIcon(faTags);
        if (resource) {
          const indexMinimal = resource as IndexMinimal;
          setLabel(indexMinimal.name || 'Unnamed index');
          setExtra(indexMinimal.id);
          setLink(`/lists/${indexMinimal.listId}/indexes/${indexMinimal.id}`);
        }
        break;

      case 'token':
        setIcon(faKey);
        if (resource) {
          const tokenMinimal = resource as TokenMinimal;
          setLabel(tokenMinimal.name || 'Unnamed token');
          setExtra(tokenMinimal.id);
          setLink(`/tokens/${tokenMinimal.id}`);
        }
        break;

      case 'user':
        setIcon(faUserAlt);
        if (resource) {
          const userMinimal = resource as UserMinimal;
          setLabel(userMinimal.displayName || 'Unnamed user');
          setExtra(userMinimal.id);
          setLink(`/users/${userMinimal.id}`);
        }
        break;
    }
  }, [resource, resourceType]);

  const close = useCallback(() => {
    onClose?.();
  }, [onClose]);

  const contents = (
    <>
      {icon && <FontAwesomeIcon icon={icon} />}
      {label}
      {extra && <span className="extra">({extra})</span>}
    </>
  );

  return (
    <div
      className={classNames(
        'resource-identifier',
        `resource-identifier-${resourceType}`,
        wrap ? 'resource-identifier-wrap' : '',
        closable ? 'resource-identifier-closable' : ''
      )}
    >
      {link ? <a href={link}>{contents}</a> : contents}
      {closable && (
        <button
          type="button"
          className="resource-identifier-close-button"
          onClick={close}
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
      {copyable && resource && (
        <CopyToClipboard
          text={resource.id}
          onCopy={() => {
            setIdCopiedToClipboard(true);
          }}
        >
          <button
            type="button"
            className="resource-identifier-copy-to-clipboard-button"
          >
            <FontAwesomeIcon
              icon={idCopiedToClipboard ? faClipboardCheck : faClipboard}
            />
          </button>
        </CopyToClipboard>
      )}
    </div>
  );
}
