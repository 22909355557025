import {
  IconDefinition,
  faAsterisk,
  faEdit,
  faEye,
  faSquarePlus,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement } from 'react';
import { Tag } from '.';
import { PermissionAction } from '../types';
import classNames from '../utilities/class-names';

type TokenPermissionActionTagStyle = {
  icon: IconDefinition;
  className: string;
  label: string;
};

const tokenPermissionActionTagStyles: {
  [key in PermissionAction]: TokenPermissionActionTagStyle;
} = {
  '*': {
    icon: faAsterisk,
    className: 'token-permission-action-tag-all',
    label: 'All',
  },
  create: {
    icon: faSquarePlus,
    className: 'token-permission-action-tag-create',
    label: 'Create',
  },
  view: {
    icon: faEye,
    className: 'token-permission-action-tag-view',
    label: 'View',
  },
  update: {
    icon: faEdit,
    className: 'token-permission-action-tag-update',
    label: 'Update',
  },
  delete: {
    icon: faTrash,
    className: 'token-permission-action-tag-delete',
    label: 'Delete',
  },
};

type TokenPermissionActionTagProps = {
  action: PermissionAction;
};

export function TokenPermissionActionTag({
  action,
}: TokenPermissionActionTagProps): ReactElement {
  let { icon, className, label } = tokenPermissionActionTagStyles[action];

  return (
    <Tag
      show
      className={classNames('token-permission-action-tag', className)}
      icon={icon}
    >
      {label}
    </Tag>
  );
}
