import {
  faFileCode,
  faFolder,
  faRefresh,
  faTags,
} from '@fortawesome/free-solid-svg-icons';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import { SingleValue } from 'react-select';
import useCachedResources from '../hooks/cached-resources';
import { IndexMinimal, ItemMinimal, ListMinimal } from '../types';
import classNames from '../utilities/class-names';
import { Button } from './Button';
import { ResourceIdentifier } from './ResourceIdentifier';
import './ResourcePicker.scss';
import { Select, SelectOptionProps } from './Select';

export type ResourcePickerProps = {
  name: string;
  resourceType: 'list' | 'item' | 'index';
  value?: string[];
  disabled?: boolean;
  onChange?: (selected: string[]) => void;
};

type SelectedResource = '*' | ListMinimal | ItemMinimal | IndexMinimal | null;

export function ResourcePicker({
  name,
  resourceType,
  value = [],
  disabled,
  onChange,
}: ResourcePickerProps): ReactElement {
  const selectRef = useRef<any>();
  const {
    lists,
    list,
    refreshLists,
    items,
    item,
    refreshItems,
    indexes,
    index,
    refreshIndexes,
  } = useCachedResources();
  const [options, setOptions] = useState<SelectOptionProps[]>([]);
  const [selectedResources, setSelectedResources] = useState<
    SelectedResource[]
  >([]);

  useEffect(() => {
    const currentResourceIds = selectedResources
      .map(selectedResource => {
        if (selectedResource === '*') {
          return '*';
        }

        return selectedResource?.id;
      })
      .filter(Boolean) as string[];

    if (
      currentResourceIds.length !== value.length ||
      currentResourceIds.some(id => !value.includes(id))
    ) {
      setSelectedResources(
        value.map(v => {
          if (v === '*') {
            return v;
          }

          switch (resourceType) {
            case 'list':
              return list(v);

            case 'item':
              return item(v);

            case 'index':
              return index(v);
          }

          return null;
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleRefresh = useCallback(() => {
    switch (resourceType) {
      case 'list':
        refreshLists();
        break;

      case 'item':
        refreshItems();
        break;

      case 'index':
        refreshIndexes();
        break;
    }
  }, [resourceType, refreshLists, refreshItems, refreshIndexes]);

  const handleChange = useCallback(
    (newSelectedResources: SelectedResource[]) => {
      onChange?.(
        newSelectedResources
          .map(selectedResource => {
            if (selectedResource === '*') {
              return '*';
            }

            return selectedResource?.id;
          })
          .filter(Boolean) as string[]
      );
    },
    [onChange]
  );

  const handleAdd = useCallback(
    (selected: SingleValue<SelectOptionProps>) => {
      if (
        selected &&
        !disabled &&
        !selectedResources.find(selectedResource => {
          if (selectedResource === '*') {
            return selectedResource === selected.value;
          }

          return selectedResource?.id === selected.value.id;
        })
      ) {
        const newSelectedResources = [...selectedResources, selected.value];
        setSelectedResources(newSelectedResources);
        handleChange(newSelectedResources);
      }

      setTimeout(() => {
        selectRef.current?.clearValue();
      }, 200);
    },
    [selectedResources, setSelectedResources, handleChange, disabled]
  );

  const handleRemove = useCallback(
    (index: number) => {
      if (!disabled) {
        const newSelectedResources = selectedResources.filter(
          (_, i) => i !== index
        );
        setSelectedResources(newSelectedResources);
        handleChange(newSelectedResources);
      }
    },
    [selectedResources, setSelectedResources, handleChange, disabled]
  );

  useEffect(() => {
    switch (resourceType) {
      case 'list':
        if (lists && Array.isArray(lists)) {
          setOptions([
            {
              label: 'All lists',
              icon: faFolder,
              value: '*',
            },
            ...lists.map(list => ({
              label: list.name || 'Unnamed list',
              extra: list.id,
              icon: faFolder,
              value: list,
            })),
          ]);
        }
        break;

      case 'item':
        if (items && Array.isArray(items)) {
          setOptions([
            {
              label: 'All items',
              icon: faFileCode,
              value: '*',
            },
            ...items.map(item => ({
              label: item.alias || 'Unnamed item',
              extra: item.id,
              icon: faFileCode,
              value: item,
            })),
          ]);
        }
        break;

      case 'index':
        if (indexes && Array.isArray(indexes)) {
          setOptions([
            {
              label: 'All indexes',
              icon: faTags,
              value: '*',
            },
            ...indexes.map(index => ({
              label: index.name || 'Unnamed index',
              extra: index.id,
              icon: faTags,
              value: index,
            })),
          ]);
        }
        break;
    }
  }, [resourceType, lists, items, indexes]);

  return (
    <div
      className={classNames(
        'resource-picker',
        disabled ? 'resource-picker-disabled' : ''
      )}
    >
      <div className="resource-picker-selected-resources">
        {selectedResources.map((resource, index) => (
          <ResourceIdentifier
            key={index}
            resourceType={resourceType}
            resource={
              resource === '*'
                ? {
                    list: {
                      name: 'All lists',
                    } as ListMinimal,
                    item: {
                      alias: 'All items',
                    } as ItemMinimal,
                    index: {
                      name: 'All indexes',
                    } as IndexMinimal,
                  }[resourceType]
                : resource
            }
            wrap
            closable
            onClose={() => {
              handleRemove(index);
            }}
          />
        ))}
      </div>
      <Select
        ref={selectRef}
        className="resource-picker-select"
        name={name}
        options={options}
        searchable
        disabled={disabled}
        onChange={handleAdd as any}
      />
      <Button
        className="resource-picker-refresh-button"
        icon={faRefresh}
        onClick={handleRefresh}
        disabled={disabled}
        minimal
      />
    </div>
  );
}
